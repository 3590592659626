import React from "react";
import Image from "components/image";
import { Link } from "gatsby"

const providers = [
  {name: 'Supabase', text: 'PostgreSQL', image: 'supabase-ico.png', link: '/database/supabase/'},
  {name: 'PlanetScale', text: 'MySQL', image: 'planetscale-ico.png', link: '/database/planetscale-backup/'},
  {name: 'Neon', text: 'Postgres', image: 'neon-ico.png', link: '/database/neon/'},
  {name: 'Amazon RDS', text: 'MySQL, PostgreSQL, MongoDB', image: 'aws-ico.png', link: '/database/aws-rds-backup/'},
  {name: 'Google Cloud DB', text: 'MySQL, PostgreSQL', image: 'google-cloud-ico.png', link: ''},
  {name: 'DigitalOcean Managed DB', text: 'MySQL, PostgreSQL, MongoDB', image: 'digitalocean-ico.png', link: ''},
  {name: 'Azure DB', text: 'MySQL, PostgreSQL', image: 'azure-ico.png', link: ''},
  {name: 'Vultr', text: 'MySQL, PostgreSQL, Redis', image: 'vultr-ico.png', link: ''},
  {name: 'Scalingo', text: 'MySQL, PostgreSQL, MongoDB, Redis', image: 'scalingo-ico.png', link: ''},
  {name: 'OVH', text: 'MySQL, PostgreSQL, Redis', image: 'ovh-ico.png', link: ''},
  {name: 'Scaleway', text: 'MySQL, PostgreSQL, Redis, MongoDB', image: 'scaleway-ico.png', link: ''},
  {name: 'Aiven', text: 'MySQL, PostgreSQL', image: 'aiven-ico.png', link: ''},
]
const BucketProviders = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-5 text-left  m-auto">
      {providers.map(provider => (
        <Link to={ (provider.link) ? provider.link : '#'} className="flex justify-between flex-col bg-white border-indigo-100 border rounded-3xl py-8 px-4 text-sm text-center">
          <div className="flex items-center relative justify-center mb-3">
            <Image filename={provider.image} alt={`${provider} backup`} className="w-12" />
          </div>
          <div>
            <p className="font-bold mb-1">{provider.name}</p>
            <p className="opacity-75">{provider.text}</p>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default BucketProviders;

import React from "react"
import {Link as LinkBase} from "gatsby";
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import DbaasProviders from "./_dbaasProviders";
import StorageProviders from "../storage-backup/_storageProviders";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="planetscale"
      title="Automated DBaaS Backup Service"
      description="Automate your PlanetScale database backups, run them on your terms and store them on any cloud provider."

    />
    <Layout.Hero
      type="primary"
      title={(<><span className="text-primary">PlanetScale</span> database backup, without limit</>)}
      subTitle={(
        <>Create automatic database backups for your MySQL Planetscale database.<br/>
          Select your schedule, your storage and get full control of your backups.
        </>)}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your PlanetScale backup" urlAtrr={{ sb_source: "website", sb_term: "planetscale-hero" }} />)}
      illustration={(
          <Ui.Image className="" filename="simplebackups-planetscale-hero.png" alt="Database as a service backup solution"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <div className="md:mb-24 ">
        <Sections.JoinBrandsSection />
      </div>
    </Ui.Container>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Schedule your backups",
                title: (<>PlanetScale backups on <span className="text-primary">your own terms</span></>),
                description: (
                    <>
                      <p>
                        Don't solely rely on fixed daily schedule hosted on PlanetScale.<br/>
                        SimpleBackups, gives you full control of your backups and fully supports PlanetScale MySQL Vitess database.

                        <div className="mt-6"><Ui.Link arrow="right" to="#features">View all features</Ui.Link></div>
                      </p>
                    </>
                ),
                image: ( <Ui.Image
                    className="shadow-2xl rounded-xl"
                    filename='simplebackups-app-scheduling.png'
                    alt="Managed database backup"
                    style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust(),
              Content_FeatureControl({subject: 'PlanetScale database'}),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">The alternative to built-in PlanetScale MySQL Backups</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">
              Meet the backup solution built for the PlanetScale's users who're looking for higher backup standards.</p>


            <div className="grid md:grid-cols-3 md:gap-8 gap-8 text-left my-20">
              <div className="bg-white rounded-xl p-8 shadow-xl">
                <h3 className="text-xl font-bold mb-3"><i className="fa fa-circle-1 text-md mr-3 text-primary"></i>Flexible schedule</h3>
                <p className="text-base">Is 1-backup a day not enough for your needs?<br/>
                  Run your backups at the time you you want, and with the retention policy you need.</p>
              </div>

              <div className="bg-white rounded-xl p-8 shadow-xl">
                <h3 className="text-xl font-bold mb-3"><i className="fa fa-circle-2 text-md mr-3 text-primary"></i>Controlled storage</h3>
                <p className="text-base">Stop trusting your backups to live where your data is hosted.<br/>Control where your backup is stored and restore them where you want.</p>
              </div>

              <div className="bg-white rounded-xl p-8 shadow-xl">
                <h3 className="text-xl font-bold mb-3"><i className="fa fa-circle-3 text-md mr-3 text-primary"></i>Backup monitoring Control</h3>
                <p className="text-base">Make sure your backups are safe and not corrupted with built-in Anomaly Detection algorithm.</p>
              </div>
            </div>


            <p className="py-5 text-lg font-bold">All you've ever wanted for your PlanetScale backups:</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium max-w-7xl">
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Backup all databases at once</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Select/Exclude tables</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Advanced database flags</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">SSL Support</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">No-infrastructure required (serveless)</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Support for (very) large database backups</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Downloadable backup archives</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Automated restore</span>
              </li>
            </ul>

            <Ui.Typography tag="h3" className="text-2xl text-center mt-20 mb-8">Wait... there's even more...</Ui.Typography>
            <Sections.FeatureSlider tags={["mysql"]} />

            <div className="my-16 text-center"><Ui.Link arrow="right" to="/features">View all features</Ui.Link></div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Database Backups?</>}
      text="Find out more and get started with our PlanetScale Backup guides"
      tags={['planetscale']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_is_dbaas_backups', 'what_is_dbaas', 'what_is_managed_database', 'can_download_backup', 'is_planetscale_built_in_backup_sufficient']}/>


  </Layout.Layout>
)

export default ContentPage
